/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

// TODO: Move styles to global.scss after full migration to app-routing
import css from "./button-primitive.module.scss";

export function ButtonPrimitive({
  children,
  className,
  type = "button",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={classNames(css.button, className)} type={type} {...props}>
      {children}
    </button>
  );
}
