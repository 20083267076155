import styled, { css } from "styled-components";

import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { NewIcon } from "components/NewIcon";

export const Actions = styled.div`
  border-top: solid 1px #b4b4b488;
  display: grid;
  grid-gap: 8px;
  margin-top: 16px;
  padding-top: 16px;

  @media ${MEDIA_QUERY.sm} {
    grid-auto-flow: column;
  }

  @media ${MEDIA_QUERY.md} {
    grid-template-columns: repeat(2, 240px);
    justify-content: end;
  }
`;

export const Button = styled(ButtonPrimitive)<{ primary?: boolean }>`
  --color: #33adff;
  border: solid 1px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    --color: rgb(91, 189, 255);
    box-shadow: 0 4px 8px 0 rgba(22, 8, 41, 0.3);
  }

  ${({ primary }) => css`
    background-color: ${primary ? "var(--color)" : "#0000"};
    border-color: ${primary ? "#0000" : "var(--color)"};
    color: ${primary ? "#fff" : "var(--color)"};
  `}
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto auto 1fr auto;

  @media ${MEDIA_QUERY.md} {
    grid-template-columns: 100px 1fr auto;
  }
`;

export const ItemAuthor = styled.p`
  display: -webkit-box;
  font-size: 12px;
  font-weight: 400;
  grid-column: 2;
  grid-row: 2;
  line-height: 18px;
  margin: 0;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media ${MEDIA_QUERY.sm} {
    font-size: 14px;
    line-height: 20px;
    max-height: 40px;
  }
`;

export const ItemCover = styled.img`
  border-radius: 4px;
  grid-column: 1;
  grid-row: 1 / 4;
  width: 100%;
`;

export const ItemPrice = styled.p`
  font-size: 16px;
  font-weight: 700;
  grid-column: 2;
  grid-row: 4;
  margin: 0;
  text-align: right;

  @media ${MEDIA_QUERY.sm} {
    grid-column: 3;
    grid-row: 1;
  }
`;

export const ItemTitle = styled.p`
  display: -webkit-box;
  font-size: 14px;
  font-weight: 700;
  grid-column: 2;
  grid-row: 1;
  line-height: 20px;
  margin: 0;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media ${MEDIA_QUERY.md} {
    font-size: 16px;
    line-height: 22px;
    max-height: 44px;
  }
`;

export const Root = styled.div`
  max-width: 100%;
  width: 730px;
`;

export const TitleIcon = styled(NewIcon)`
  color: #4dffc5;
  margin-right: 10px;
`;

export const Title = styled.p`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  margin: 30px 0 40px;
  text-align: center;

  @media ${MEDIA_QUERY.sm} {
    font-size: 18px;
    margin-top: 0;
  }
`;
