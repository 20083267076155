import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import css from "./menu-mobile.module.scss";

interface Props extends PropsWithChildren {
  open: boolean;
}

export function MenuMobile({ children, open }: Props) {
  const timeoutRef = useRef<number | NodeJS.Timeout>();
  const [tmpChildren, setTmpChildren] = useState(null);

  useEffect(() => {
    if (open) {
      clearTimeout(timeoutRef.current);
      setTmpChildren(children);
    } else {
      timeoutRef.current = setTimeout(() => {
        setTmpChildren(null);
      }, 200);
    }
  }, [children, open]);

  return (
    <dialog className={css.menuRoot} open={open}>
      {tmpChildren}
    </dialog>
  );
}
