"use client";

import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { isServer } from "utils/runtime";

import { ToasterContext } from "modules/Toaster";

import Toaster from "modules/Toaster/components/Toaster";
import Toast from "modules/Toaster/components/Toast";

const ToasterContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  /*
   * Memos
   */
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);

  /*
   * States
   */
  const [isMounted, setMounted] = useState(false);
  const [toasts, setToasts] = useState<RequiredKeys<IToast, "id" | "type">[]>([]);

  /*
   * Callbacks
   */
  const closeToast = useCallback((id: string) => {
    setToasts((oldToasts) => oldToasts.filter((_toast) => _toast.id !== id));
  }, []);

  const showToast = useCallback((toast: IToast) => {
    const type: IToast["type"] = toast.type || "info";

    const toastId = toast.id ? toast.id : `${type}-${Date.now() * Math.random()}`;

    const newToast = {
      ...toast,
      id: toastId,
      type,
      close: () => closeToast(toastId),
    };

    setToasts((oldToasts) => [...oldToasts, newToast]);

    return newToast;
  }, []);

  /*
   * Effects
   */
  useEffect(() => {
    document.body.append(root);
    setMounted(true);

    return () => {
      setMounted(false);
      root.remove();
    };
  }, []);

  /*
   * Render
   */
  return (
    <ToasterContext.Provider value={{ closeToast, showToast }}>
      {children}
      {isMounted
        ? createPortal(
            <Toaster>
              {toasts.map((toast) => (
                <Toast {...toast} key={toast.id} onClose={closeToast} />
              ))}
            </Toaster>,
            root
          )
        : null}
    </ToasterContext.Provider>
  );
};

export default ToasterContextProvider;
