"use client";

import React, { PropsWithChildren } from "react";

import ScrollTop from "./scroll-top/scroll-top";
import TopBar from "./top-bar";
import { MenuMobileItem } from "./menu-mobile";
import MobileAppBanner from "./mobile-app-banner";
import Footer from "./footer";

import css from "./layout.module.scss";

export function Layout({ children, catalogItems }: PropsWithChildren<{ catalogItems: MenuMobileItem[] }>) {
  return (
    <>
      <div className={css.layout}>
        <TopBar catalogItems={catalogItems} />
        <div className={css.content}>{children}</div>
        <Footer catalogItems={catalogItems} />
        <ScrollTop />
      </div>
      <MobileAppBanner />
    </>
  );
}
