import { HS_SET_STATE } from "./actions";

export default function reducer(state, action) {
  switch (action.type) {
    case HS_SET_STATE:
      return action.payload;
    default:
      return state;
  }
}
