type EventListener = (event: Event) => void;

export class EventManager {
  private readonly _listeners = new Map<string, Set<EventListener>>();

  public dispatch = (event: Event) => {
    if (!this._listeners.has(event.type)) {
      return;
    }

    this._listeners.get(event.type).forEach((listener) => listener(event));
  };

  public on = (name: string, listener: EventListener) => {
    if (!this._listeners.has(name)) {
      this._listeners.set(name, new Set());
    }

    this._listeners.get(name).add(listener);
  };

  public off = (name?: string, listener?: EventListener) => {
    if (!name) {
      // Clear all listeners
      this._listeners.clear();
      return;
    }

    if (!this._listeners.has(name)) {
      // Listeners not found
      return;
    }

    if (listener) {
      // Remove specific listener
      this._listeners.get(name).delete(listener);
    } else {
      // Remove all listener for specific name
      this._listeners.delete(name);
    }
  };
}
