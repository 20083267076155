import styled, { css } from "styled-components";

const trackStyle = css`
  background: linear-gradient(90deg, currentColor 50%, hsla(0, 0%, 100%, 0.3) 50%);
  background-position-x: var(--track-fill, 100%);
  background-size: 200% 100%;
  border: none;
  border-radius: calc(var(--track-height) * 0.5);
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  height: var(--track-height);
  transition: color 0.2s ease;
  width: calc(100% - var(--thumb-size));
`;

const thumbStyle = css`
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: var(--thumb-size);
  transform: scale(var(--thumb-initial-scale));
  transition: transform 0.2s ease;
  width: var(--thumb-size);
`;

const thumbFocusStyle = css`
  background-color: var(--thumb-color);
  transform: scale(1.2);
`;

const trackFocusStyle = css`
  color: var(--track-color);
`;

export const Input = styled.input<{ compact?: boolean; invertColors?: boolean; noThumb?: boolean }>`
  appearance: none;
  background: transparent;
  height: var(--thumb-size);
  width: 100%;

  &::-webkit-slider-thumb {
    appearance: none;
    margin-top: calc(var(--thumb-size) * -0.5 + var(--track-height) * 0.5);
    ${thumbStyle}
  }

  &::-moz-range-thumb {
    ${thumbStyle}
  }

  &::-ms-thumb {
    ${thumbStyle}
  }

  &::-webkit-slider-runnable-track {
    ${trackStyle}
  }

  &::-moz-range-track {
    ${trackStyle}
  }

  &::-ms-track {
    ${trackStyle}
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;

    &::-webkit-slider-thumb {
      ${thumbFocusStyle}
    }

    &::-moz-range-thumb {
      ${thumbFocusStyle}
    }

    &::-ms-thumb {
      ${thumbFocusStyle}
    }

    &::-webkit-slider-runnable-track {
      ${trackFocusStyle}
    }

    &::-moz-range-track {
      ${trackFocusStyle}
    }

    &::-ms-track {
      ${trackFocusStyle}
    }
  }

  ${({ compact, invertColors, noThumb }) => css`
    --track-color: ${invertColors ? "#fff" : "#33adff"};
    --track-height: ${compact ? 6 : 8}px;
    --thumb-color: ${invertColors ? "#33adff" : "#fff"};
    --thumb-size: ${compact ? 15 : 20}px;
    --thumb-initial-scale: ${noThumb ? 0 : 1};
  `}
`;
