import React, { ComponentProps } from "react";
import NextLink from "next/link";
import { useLocale } from "next-intl";

import { getPathname, Pathname } from "pathnames";
import { buildLink } from "utils/buildLink";

import "./link.scss";

interface Props extends Omit<ComponentProps<typeof NextLink>, "as" | "href"> {
  params?: Record<string, string>;
  route: Pathname;
}

export function InternalLink({ children, className, params = {}, route, ...props }: Props) {
  const currentLocale = useLocale();
  const { locale = currentLocale, ...linkParams } = params;

  return (
    <NextLink
      as={buildLink(route, locale, linkParams)}
      className={className}
      href={{ pathname: getPathname(route), query: { locale, ...linkParams } }}
      {...props}
    >
      {children}
    </NextLink>
  );
}
