import { useEffect, useState } from "react";
import { useLocale } from "next-intl";

import { getLocationInfo } from "utils/getLocationInfo";
import { getSearchScreen, SearchScreen } from "resources/AudiotekaApi";

export function useRecommendations(): SearchScreen | null {
  const locale = useLocale();

  const [recommendations, setRecommendations] = useState<SearchScreen | null>(null);

  useEffect(() => {
    async function loadRecommendations() {
      try {
        const { catalogId } = getLocationInfo(locale);
        const response = await getSearchScreen(catalogId);

        if (response?._embedded["app:screen-section"]) {
          setRecommendations(response);
        } else {
          setRecommendations(null);
        }
      } catch {
        setRecommendations(null);
      }
    }

    loadRecommendations();

    return () => {
      setRecommendations(null);
    };
  }, [locale]);

  return recommendations;
}
