import React, { ComponentProps, FC, FormEvent, InputHTMLAttributes, memo, useCallback } from "react";
import styled from "styled-components";

import { Input } from "./range.style";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "onChange">, ComponentProps<typeof Input> {
  max?: number;
  min?: number;

  onChange(newValue: number): void;

  step?: number;
  value: number;
}

export const Range: FC<Props> = memo(({ max = 1, min = 0, onChange, value, step = 1, style = {}, ...rest }) => {
  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.valueAsNumber);
    },
    [onChange]
  );

  const range = max - min;
  const percentage = (value - min) / range;
  const steps = range / step;
  const validSteps = Math.ceil((range * percentage) / step);
  const trackFill = `${100 - Math.min(Math.max((validSteps / steps) * 100, 0), 100)}%`;

  const rangeStyle = {
    ...style,
    "--track-fill": trackFill,
  };

  return (
    <Input
      max={max}
      min={min}
      onChange={handleChange}
      step={step}
      style={rangeStyle as any}
      type="range"
      value={value}
      {...rest}
    />
  );
});

export default styled(Range)``;
