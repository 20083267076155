import React from "react";

import { HomeScreen } from "resources/AudiotekaApi";

export interface ApplicationState {
  state: {
    homeScreen: HomeScreen;
  };
  dispatch: React.Dispatch<unknown>;
}

const ApplicationStateContext = React.createContext<ApplicationState>(undefined);

export default ApplicationStateContext;
