export const isServer = typeof window === "undefined";

export const browserName = !isServer
  ? (() => {
      if ((navigator.userAgent.indexOf("Opera") > -1 || navigator.userAgent.indexOf("OPR")) > -1) {
        return "Opera";
      }
      if (navigator.userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
      }
      if (navigator.userAgent.indexOf("Safari") > -1) {
        return "Safari";
      }
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        return "Firefox";
      }

      return "Unknown";
    })()
  : "Unknown";
