import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

import { isServer } from "utils/runtime";
import { NewIcon } from "components/NewIcon";
import { disableScroll, enableScroll } from "utils/window";
import { Close, Container, Root } from "./styled";

export interface Props extends PropsWithChildren {
  center?: boolean;
  dark?: boolean;
  mobileFull?: boolean;

  onClose(): void;

  open: boolean;
}

const Modal: React.FC<Props> = ({ center, children, dark, mobileFull, onClose, open }) => {
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    document.body.appendChild(root);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(root);
      enableScroll(root);
    };
  }, []);

  useEffect(() => {
    if (open) {
      disableScroll(root);
    } else {
      enableScroll(root);
    }
  }, [open, root]);

  const hBackdropClick = useCallback(
    (event: React.MouseEvent) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  return isMounted
    ? ReactDOM.createPortal(
        <Root center={center} mobileFull={mobileFull} open={open} onClick={hBackdropClick}>
          <Container dark={dark} mobileFull={mobileFull}>
            <Close onClick={onClose}>
              <NewIcon icon="close" width="24" height="24" />
            </Close>
            {children}
          </Container>
        </Root>,
        root
      )
    : null;
};

export default Modal;
