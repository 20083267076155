import React from "react";
import { useTranslations } from "next-intl";

import { Link } from "app-components/link";
import { NewIcon } from "components/NewIcon";
import { useUser } from "modules/user";
import { ButtonPrimitive } from "app-components/button/button-primitive";

import { MenuMobile, MenuMobileContent, MenuMobileHeader } from "../../../menu-mobile";
import { userMenuItems } from "../consts";
import css from "./user-menu.module.scss";

export const UserMenu = ({ onClose, open }) => {
  const t = useTranslations();
  const { email: userEmail, signOut } = useUser();

  const handleLogout = () => {
    signOut();
    onClose();
  };

  return (
    <MenuMobile open={open}>
      <MenuMobileHeader onClose={onClose}>
        <div className={css.title}>
          <NewIcon icon="user" width="24" height="24" />
          <div>
            {t("navigation.my_account")}
            <p className={css.description}>{userEmail}</p>
          </div>
        </div>
      </MenuMobileHeader>
      <MenuMobileContent>
        {userMenuItems.map((item) => (
          <Link className={css.link} key={item.name} route={item.route} title={t(item.name)} onClick={onClose}>
            {item.icon && <NewIcon icon={item.icon} width="32" height="32" />}
            {t(item.name)}
          </Link>
        ))}
        <div className={css.logoutButtonWrapper}>
          <ButtonPrimitive className={css.logoutButton} title={t("navigation.logout")} onClick={handleLogout}>
            <NewIcon icon="logout" width="32" height="32" />
            {t("navigation.logout")}
          </ButtonPrimitive>
        </div>
      </MenuMobileContent>
    </MenuMobile>
  );
};
