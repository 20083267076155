import gtm from "react-gtm-module";

export function trackUpperRightMenuClickEvent(name: "favourites" | "collection" | "last_listen" | "login") {
  gtm.dataLayer({
    dataLayer: {
      event: "upper_right_menu",
      upper_right_menu_click: name,
    },
  });
}
