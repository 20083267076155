export const userMenuItems = [
  {
    name: "navigation.favourites",
    title: "navigation.favourites",
    icon: "heart",
    route: "favourites",
  },
  {
    name: "navigation.my_shelf",
    title: "navigation.my_shelf",
    icon: "bookshelf",
    route: "shelf",
  },
  {
    name: "navigation.recently_played",
    title: "navigation.recently_played",
    icon: "history",
    route: "recentlyPlayed",
  },
  {
    name: "navigation.profile_settings",
    title: "navigation.profile_settings",
    icon: "gear",
    route: "userPanel",
  },
] as const;
