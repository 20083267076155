import { isServer } from "utils/runtime";

import type { UserState } from "./user.types";

interface StateItem {
  state: UserState;
  expires: number | null;
}

type UserStateSubscriber = (state: UserState) => void;

const AUTH_KEY = "authState";

class UserStore {
  private readonly _store: Storage;

  private readonly _subscribers = new Set<UserStateSubscriber>();

  public static parseItem(item: string): StateItem {
    try {
      const { state = null, expires = null } = JSON.parse(item);

      return { state, expires };
    } catch {
      return { state: null, expires: null };
    }
  }

  constructor() {
    if (isServer) {
      return;
    }

    this._store = localStorage;

    window.addEventListener("storage", this._handleStorage);
  }

  private _handleStorage = (event: StorageEvent) => {
    if (event.key === AUTH_KEY) {
      const { state: oldState } = UserStore.parseItem(event.oldValue);
      const { state: newState } = UserStore.parseItem(event.newValue);

      if (oldState !== newState) {
        this._subscribers.forEach((subscriber) => subscriber(newState));
      }
    }
  };

  public getState(): UserState {
    try {
      const { state, expires } = UserStore.parseItem(this._store.getItem(AUTH_KEY));

      if (!expires || expires > Date.now()) {
        return state;
      }

      return null;
    } catch {
      return null;
    }
  }

  public setState(state: UserState, expires?: Date) {
    const item: StateItem = { state, expires: expires ? expires.getTime() : null };

    this._store.setItem(AUTH_KEY, JSON.stringify(item));
  }

  public subscribe(subscriber: UserStateSubscriber) {
    this._subscribers.add(subscriber);

    return () => {
      this._subscribers.delete(subscriber);
    };
  }
}

export const userStore = new UserStore();
