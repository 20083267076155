import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { HalController } from "api-web-client";
import gtm from "react-gtm-module";
import { useLocale } from "next-intl";
import classNames from "classnames";

import { NewIcon } from "components/NewIcon";
import { Container } from "app-components/container";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import { Teaser } from "modules/DesignSystem/components/teaser";
import { getGTMProduct, setSelectedItemInLocalStorage } from "utils/products";
import { useTeaserTracking } from "modules/teaser-tracking";
import { getAudiobook } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";

import { Title } from "./title";
import css from "./teaser-list.module.scss";

interface Props {
  className?: string;
  colorless?: boolean;
  compact?: boolean;
  elements: (HalController<HALProduct> | HALProduct)[];
  fluid?: boolean;
  lazyLoading?: boolean;
  more?: {
    link: string;
    text: string;
  };
  noPlayer?: boolean;
  noPrice?: boolean;
  title?: string;
  trackingId?: string;
}

const TeaserList = ({
  className,
  colorless,
  compact,
  title,
  lazyLoading,
  more,
  elements,
  fluid,
  noPlayer,
  noPrice,
  trackingId,
}: Props) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const teaserTracking = useTeaserTracking();
  const locale = useLocale();

  const [arrowState, setArrowState] = useState({
    hasScrollRight: false,
    hasScrollLeft: false,
  });

  const performScroll = (newPos: number) => {
    sliderRef.current?.scrollTo({
      left: newPos,
      behavior: "smooth",
    });
  };

  const onNextClick = () => {
    const { width } = sliderRef.current.getBoundingClientRect();
    performScroll(sliderRef.current.scrollLeft + width);
  };

  const onPrevClick = () => {
    const { width } = sliderRef.current.getBoundingClientRect();
    performScroll(sliderRef.current.scrollLeft - width);
  };

  const gtmClickTracking = useCallback(
    async (product: HalController<HALProduct> | HALProduct) => {
      if (!trackingId) {
        return;
      }

      const { catalogId } = getLocationInfo(locale);

      const isAudiobook = /\/audiobook\//.test(product.data.deeplink);
      const audiobook = isAudiobook ? await getAudiobook(product.data.id, catalogId) : null;

      gtm.dataLayer({
        dataLayer: {
          event: "select_item",
          ecommerce: {
            item_list_id: trackingId,
            item_list_name: title,
            items: [
              {
                ...getGTMProduct({
                  audiobook: audiobook ? HalController.fromObject(audiobook as any) : null,
                  product,
                }),
                item_list_id: trackingId,
                item_list_name: title,
              },
            ],
          },
        },
      });
    },
    [elements, locale, trackingId, title]
  );

  const onTeaserClick = (product: HalController<HALProduct> | HALProduct) => {
    if (trackingId && title) {
      setSelectedItemInLocalStorage(product.data.id, { id: trackingId, name: title });
    }
    gtmClickTracking(product);
  };

  const onSliderScroll = useCallback(
    debounce(() => {
      if (!sliderRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, offsetWidth } = sliderRef.current;
      const hasScrollRight = scrollLeft + offsetWidth < scrollWidth;
      const hasScrollLeft = scrollLeft > 0;
      setArrowState({
        hasScrollRight,
        hasScrollLeft,
      });
    }, 100),
    []
  );

  useEffect(() => {
    onSliderScroll();
  }, []);

  useEffect(() => {
    sliderRef.current?.scrollTo({ left: 0, behavior: "auto" });
  }, [elements]);

  useEffect(() => {
    teaserTracking.addList(
      trackingId,
      elements.map((teaser) => teaser.data.id)
    );

    return () => {
      teaserTracking.removeList(trackingId);
    };
  }, [elements, teaserTracking, trackingId]);

  const ContainerComponent = fluid ? Container : ContainerBase;

  return (
    <ContainerComponent className={className}>
      <Title title={title} more={more} />
      <div className={classNames(css.carusel, "adtk-item-list")} data-list-id={trackingId} data-list-name={title}>
        <div
          className={classNames(css.caruselSlider, { [css.compact]: compact })}
          ref={sliderRef}
          onScroll={onSliderScroll}
        >
          {elements.map((teaser) => (
            <div className={classNames(css.sliderItem, { [css.compact]: compact })} key={teaser.data.id}>
              <Teaser
                action={noPlayer ? undefined : "play"}
                colorless={colorless}
                lazyLoading={lazyLoading}
                onClick={() => onTeaserClick(teaser)}
                noPrice={noPrice}
                product={teaser}
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          className={classNames(css.arrow, css.left, { [css.compact]: compact })}
          disabled={!arrowState.hasScrollLeft}
          ref={prevRef}
          onClick={onPrevClick}
        >
          <NewIcon className={css.arrowLeft} icon="arrow" width={30} height={30} />
        </button>
        <button
          type="button"
          className={classNames(css.arrow, css.right, { [css.compact]: compact })}
          disabled={!arrowState.hasScrollRight}
          ref={nextRef}
          onClick={onNextClick}
        >
          <NewIcon icon="arrow" width={30} height={30} />
        </button>
      </div>
    </ContainerComponent>
  );
};

export default TeaserList;
