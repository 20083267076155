import { useEffect, useState } from "react";
import cookie from "js-cookie";

export function useLaunchTestDomain() {
  const [launchTestDomain, setLaunchTestDomain] = useState<string>(process.env.AUDIOTEKA_LEGACY_URL);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (url.searchParams.has("full-launch-test")) {
      cookie.set("fltest", url.searchParams.get("full-launch-test"), { path: "/" });
    }

    if (cookie.get("fltest") === "1") {
      setLaunchTestDomain(process.env.SITE_URL);
    }
  }, []);

  return launchTestDomain;
}
