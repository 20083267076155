import React, { AnchorHTMLAttributes, ForwardedRef, forwardRef } from "react";
import NextLink, { LinkProps } from "next/link";
import { useLocale } from "next-intl";
import styled from "styled-components";

import { getPathname, Pathname } from "pathnames";
import { filterProps } from "utils/react";
import { buildLink } from "utils/buildLink";

export interface LinkParams {
  route?: Pathname;
  params?: Record<string, string>;
  href?: string;
}

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkParams &
  Omit<LinkProps, "href"> & {
    parserDisabled?: boolean;
  };

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ route, params, children, href, parserDisabled, ...props }, ref) => {
    const currentLocale = useLocale();

    if (parserDisabled) {
      return (
        <a {...filterProps(props, "a")} href={href}>
          {children}
        </a>
      );
    }

    if (href) {
      const anchorProps: AnchorHTMLAttributes<HTMLAnchorElement> & { ref: ForwardedRef<HTMLAnchorElement> } = {
        ...filterProps(props, "a"),
        href: href.replace("[lang]", currentLocale),
        ref,
      };
      const isInternal = href.startsWith(process.env.SITE_URL);

      if (!isInternal && anchorProps.target === undefined) {
        anchorProps.target = "_blank";
      }

      return <a {...anchorProps}>{children}</a>;
    }

    const { locale = currentLocale, ...paramsWithoutLocale } = params || {};

    const linkProps: LinkProps & { ref: ForwardedRef<HTMLAnchorElement> } = {
      ...filterProps(props, "a"),
      as: buildLink(route, locale, paramsWithoutLocale),
      href: { pathname: getPathname(route), query: { locale, ...paramsWithoutLocale } },
      ref,
    };

    return <NextLink {...linkProps}>{children}</NextLink>;
  }
);

export default styled(Link)`
  text-decoration: none;
`;
