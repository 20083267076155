import React from "react";
import { useLocale, useTranslations } from "next-intl";
import classNames from "classnames";

import { NewIcon } from "components/NewIcon";
import { buildLink } from "utils/buildLink";
import { ButtonPrimitive } from "app-components/button/button-primitive";
import { useRouter } from "utils/next-router-provider";

import css from "./search-input.module.scss";

type SearchInputProps = {
  device: "desktop" | "mobile";
  value: string;
  onChange: (value: string) => void;
  clearResults: () => void;
  onFocus: () => void;
};

export const SearchInput = ({ device, value, onChange, clearResults, onFocus }: SearchInputProps) => {
  const t = useTranslations();
  const locale = useLocale();
  const { push } = useRouter();

  const ref = React.useRef<HTMLInputElement>(null);

  const onClear = () => {
    clearResults();
    ref.current?.focus();
  };

  const handleSearch = () => {
    push(buildLink("search", locale, { phrase: value }));

    clearResults();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value.trim() === "") return;

    handleSearch();
    ref.current?.blur();
  };

  return (
    <form className={classNames(css.searchInputForm, css[device])} onSubmit={onSubmit}>
      <input
        className={css.input}
        ref={ref}
        type="text"
        placeholder={t("navigation.search")}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
      />
      <div className={css.buttonsWrapper}>
        {value && (
          <ButtonPrimitive type="button" className={css.clearButton} onClick={onClear}>
            <NewIcon icon="close" width="24" height="24" />
          </ButtonPrimitive>
        )}
        <ButtonPrimitive
          type="button"
          className={css.searchButton}
          onClick={handleSearch}
          disabled={value.trim() === ""}
        >
          <NewIcon icon="search" width="24" height="24" />
        </ButtonPrimitive>
      </div>
    </form>
  );
};
