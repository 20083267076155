import { HalController } from "api-web-client";
import gtm from "react-gtm-module";

import { getProductType } from "utils/products";

interface AddToCartPayload {
  cart: HalController<HALCart>;
  cartItemIndex: number;
  cartItemDetails: HALCartProduct;
  product: HALProduct;
}

export function trackAddToCartEvent({ cart, cartItemIndex, cartItemDetails, product }: AddToCartPayload) {
  gtm.dataLayer({
    dataLayer: {
      event: "add_to_cart",
      ecommerce: {
        currency: cart.data.currency,
        value: cart.data.total_price / 100,
        items: [
          {
            coupon: cart.data.promocode,
            discount: (cartItemDetails.final_user_price - cartItemDetails.price) / 100,
            index: cartItemIndex,
            item_id: cartItemDetails.id,
            item_name: product.data.name,
            item_variant: getProductType(product),
            price: cartItemDetails.final_user_price / 100,
            quantity: 1,
          },
        ],
      },
    },
  });
}
