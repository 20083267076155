import React, { PropsWithChildren, useEffect, useState, createContext, useContext } from "react";
import { HalController } from "api-web-client";
import gtm from "react-gtm-module";
import { useLocale, useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";

import { useToaster } from "modules/Toaster";
import { useUser } from "modules/user";
import { getPrices } from "modules/Audiobook/utils";
import { getGTMProduct, getListData } from "utils/products";
import {
  addToFavourites,
  getAudiobook,
  getFavouritesCount,
  getProduct,
  removeFromFavourites,
} from "resources/AudiotekaApi";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import { getLocationInfo } from "utils/getLocationInfo";
import { useRouterPush } from "utils/useRouterPush";

async function gtmLike(id: string, locale: string) {
  const { catalogId, currency } = getLocationInfo(locale);

  const product = await getProduct(id, catalogId);
  const audiobook = await getAudiobook(product.id, catalogId);
  const listData = getListData(product.id);

  const halProduct = HalController.fromObject<HALProduct>(product as unknown as HALObject);
  const halAudiobook = HalController.fromObject<HALAudiobook>(audiobook as unknown as HALObject);

  const prices = getPrices({
    price: product.price,
    price_for_subscribers: product.price_for_subscribers,
    discount_price: product.discount_price,
    lowest_price: product.lowest_price,
  });

  gtm.dataLayer({
    dataLayer: {
      event: "add_to_wishlist",
      ecommerce: {
        currency,
        value: prices.final,
        items: [getGTMProduct({ product: halProduct, audiobook: halAudiobook, ...listData })],
      },
    },
  });
}
interface Context {
  count: number;
  like: (id: string) => Promise<void>;
  dislike: (id: string) => Promise<void>;
}

const FavouritesContext = createContext<Context>({
  count: 0,
  like: async () => {},
  dislike: async () => {},
});

export function useFavourites() {
  return useContext(FavouritesContext);
}

export function FavouritesProvider({ children }: PropsWithChildren) {
  const locale = useLocale();
  const routerPush = useRouterPush();
  const t = useTranslations();
  const toaster = useToaster();
  const user = useUser();
  const { signInRedirectParams } = useSignInRedirectParams();

  const [count, setCount] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      try {
        const response = await getFavouritesCount();
        setCount(response.total);
      } catch (e) {
        Sentry.captureException(`FavouritiesProvider | Failed to get favourites count | Error: ${e}`);
      }
    }

    if (user.isLoggedIn) {
      fetchCount();
    } else {
      setCount(0);
    }
  }, [user.isLoggedIn]);

  async function like(id: string) {
    if (!user.isLoggedIn) {
      routerPush("signIn", signInRedirectParams);
      return;
    }

    try {
      await addToFavourites(id);
      toaster.closeToast(`favourite--${id}`);
      gtmLike(id, locale as string);
      setCount((prevCount) => prevCount + 1);
    } catch (e) {
      Sentry.captureException(`FavouritiesProvider | Failed to add to favourites | Error: ${e}`);
    }
  }

  async function dislike(id: string) {
    try {
      await removeFromFavourites(id);
      toaster.showToast({
        actions: [
          {
            closeOnClick: true,
            onClick: () => like(id),
            text: t("favourites.removed_cta"),
          },
        ],
        actionsInline: true,
        autoClose: 5000,
        canClose: false,
        id: `favourite--${id}`,
        title: t("favourites.removed"),
      });

      setCount((prevCount) => prevCount - 1);
    } catch (e) {
      Sentry.captureException(`FavouritiesProvider | Failed to remove from favourites | Error: ${e}`);
    }
  }

  return <FavouritesContext.Provider value={{ count, dislike, like }}>{children}</FavouritesContext.Provider>;
}
