import React, { ComponentProps } from "react";

import { ExternalLink } from "./external-link";
import { InternalLink } from "./internal-link";

type Props = ComponentProps<typeof ExternalLink> | ComponentProps<typeof InternalLink>;

export function Link(props: Props) {
  if ("route" in props) {
    return <InternalLink {...props} />;
  }

  return <ExternalLink {...props} />;
}
