export const promoTypesConfig = {
  club: {
    cookie: {
      key: "w3club",
    },
    title: "top_promo_bar.club.title",
    cta: "top_promo_bar.club.cta",
    href: "top_promo_bar.club.url",
  },
} as const;
