import React, { forwardRef, HTMLAttributes } from "react";
import classNames from "classnames";

import css from "./container.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: "default" | "compact" | "full";
}

export const Container = forwardRef<HTMLDivElement, Props>(
  ({ children, className, size = "default", ...props }, ref) => {
    return (
      <div className={classNames(css.container, className, size !== "default" && css[size])} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);
