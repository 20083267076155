"use client";

import { HalController } from "api-web-client/src";
import { createContext } from "react";

import { AudiobookPlayNext } from "./player.types";

export type SetNumberFn = (time: number) => number;

export interface AudiobookChapter {
  available: boolean;
  endTime: number;
  startTime: number;
  timeString: string;
  title: string;
}

export interface PlayerLoadOptions {
  autoPlay?: boolean;
}

export interface PlayerContext {
  audio: {
    currentTime: number;
    duration: number;
    hasEnded: boolean;
    isMuted: boolean;
    isPlaying: boolean;
    playbackRate: number;
    volume: number;
  };

  audiobook: {
    authors: {
      id: string;
      name: string;
      slug: string;
    }[];
    chapters: AudiobookChapter[];
    cover: URL;
    id: string;
    isBasicPlanLimited: boolean;
    isFree: boolean;
    isSample: boolean;
    playNext: AudiobookPlayNext;
    progress: number;
    slug: string;
    title: string;
  } | null;
  isLoading: boolean;
  isMaximized: boolean;

  load(audiobook: HalController | string, options?: PlayerLoadOptions): Promise<void>;

  minimize(): void;

  play(): void;

  pause(): void;

  pauseReporting(): void;

  resumeReporting(): void;

  setPlaybackRate(rate: number): void;

  setTime(time: number | SetNumberFn): void;

  setVolume(volume: number | SetNumberFn): void;

  toggleMaximize(): void;

  toggleMute(): void;

  togglePlay(): void;

  unload(): void;
}

export const playerContext = createContext<PlayerContext>({
  audio: {
    currentTime: 0,
    duration: 0,
    hasEnded: false,
    isMuted: false,
    isPlaying: false,
    playbackRate: 1,
    volume: 1,
  },
  audiobook: null,
  isLoading: false,
  isMaximized: false,
  async load() {
    return undefined;
  },
  minimize() {},
  play() {},
  pause() {},
  pauseReporting() {},
  resumeReporting() {},
  setPlaybackRate() {},
  setTime() {},
  setVolume() {},
  toggleMaximize() {},
  toggleMute() {},
  togglePlay() {},
  unload() {},
});
