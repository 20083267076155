import { htmlElementAttributes } from "html-element-attributes";

export function filterProps<Tag extends keyof JSX.IntrinsicElements>(
  props: { [key: string]: any },
  tag: Tag
): JSX.IntrinsicElements[Tag] {
  const filtered = {} as JSX.IntrinsicElements[typeof tag];

  const allowedKeys = [...htmlElementAttributes["*"], ...htmlElementAttributes[tag as string], "className"];

  Object.entries(props).forEach(([key, value]) => {
    if (
      /^on[A-Z]/.test(key) || // Events
      /^(aria|data)-/.test(key) || // Aria & dataSet
      allowedKeys.includes(key)
    ) {
      filtered[key] = value;
    }
  });

  return filtered;
}
