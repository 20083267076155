import { LinkParams } from "components/link";

// eslint-disable-next-line import/prefer-default-export
export function deeplinkTranslator(link: string, slug?: string): LinkParams {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, route, ...params] = link.match(/lstn\.link\/(.*?)\/([^/?]*)\/?(\?.*)?$/) || [];

  switch (route) {
    case "player":
      return {
        route: "player",
        params: {
          id: slug || params[0],
        },
      };
    case "cycle":
    case "cycle-products":
      return {
        route: "cycle",
        params: {
          id: slug || params[0],
        },
      };
    case "podcast":
    case "podcast-products":
      return {
        route: "podcast",
        params: {
          id: slug || params[0],
        },
      };
    case "audiobook":
      return {
        route: "audiobook",
        params: {
          id: slug || params[0],
        },
      };
    case "catalog":
      if (params.length) {
        return {
          route: "category",
          params: {
            id: slug || params[0],
          },
        };
      }

      return { route: "catalog" };
    default:
      return {
        href: link,
      };
  }
}
