import React, { FC, PropsWithChildren, useMemo } from "react";
import gtm from "react-gtm-module";

import { getGTMProduct } from "utils/products";
import { isServer } from "utils/runtime";

import { teaserTrackingContext } from "./teaser-tracking.context";
import { TeaserTrackingContext } from "./teaser-tracking.types";

export const TeaserTrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const teaserMap = useMemo(() => new Map<string, HALProduct>(), []);
  const listMap = useMemo(() => new Map<string, string[]>(), []);

  const observer = useMemo(
    () =>
      isServer
        ? null
        : new IntersectionObserver(
            (entries, _observer) => {
              const collection = new Map<HTMLElement | undefined, HALProduct[]>();

              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const item = entry.target as HTMLElement;
                  const list = item.closest<HTMLElement>(".adtk-item-list");

                  if (!teaserMap.has(item.dataset.itemId)) {
                    return;
                  }

                  if (!collection.has(list)) {
                    collection.set(list, []);
                  }

                  collection.get(list).push(teaserMap.get(item.dataset.itemId));
                  teaserMap.delete(item.dataset.itemId); // to prevent event duplication

                  _observer.unobserve(entry.target);
                }
              });

              collection.forEach((items, list) => {
                const listId = list?.dataset.listId;
                const listName = list?.dataset.listName;
                const itemIDs = listId ? listMap.get(listId) || [] : [];

                gtm.dataLayer({
                  dataLayer: {
                    event: "view_item_list",
                    ecommerce: {
                      item_list_id: listId,
                      item_list_name: listName,
                      // @ts-ignore
                      items: items.map((item) =>
                        getGTMProduct({
                          product: item,
                          index: itemIDs.indexOf(item.data.id),
                          listId,
                          listName,
                        })
                      ),
                    },
                  },
                });
              });
            },
            { threshold: 0.5 }
          ),
    []
  );

  const value = useMemo(
    (): TeaserTrackingContext => ({
      addList(id: string, teaserIds: string[]) {
        listMap.set(id, teaserIds);
      },
      removeList(id: string) {
        listMap.delete(id);
      },
      addTeaser(id, teaser) {
        teaserMap.set(id, teaser);
      },
      removeTeaser(id) {
        teaserMap.delete(id);
      },
      updateObserver() {
        document.querySelectorAll<HTMLElement>(".adtk-item").forEach((item) => {
          observer.observe(item);
        });
      },
    }),
    [observer]
  );

  return <teaserTrackingContext.Provider value={value}>{children}</teaserTrackingContext.Provider>;
};
