import React, { AnchorHTMLAttributes } from "react";
import { useLocale } from "next-intl";

import "./link.scss";

export function ExternalLink({
  children,
  className,
  href,
  target = "_blank",
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const locale = useLocale();

  return (
    <a className={className} target={target} href={href.replace("[locale]", locale)} {...props}>
      {children}
    </a>
  );
}
