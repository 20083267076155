import React from "react";

import { Root, TabLink, TabLinks } from "./tabs.styled";

type TabProps = React.PropsWithChildren<{
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
}>;

export const Tab = ({ children }: TabProps) => <div>{children}</div>;

type TabElement = React.ReactElement<React.ComponentProps<typeof Tab>>;

export const TabList = ({ children }: { children: TabElement[] }) => {
  const tabLinksRef = React.useRef<(HTMLElement | null)[]>([]);

  const tabs = children.filter((child) => child.type === Tab);

  const [activeTabKey, setActiveTabKey] = React.useState<string | number>(tabs[0].key || tabs[0].props.name);
  const [activeLinkElement, setActiveLinkElement] = React.useState<HTMLElement | null>(null);

  const activeTab = tabs.find((tab) => (tab.key || tab.props.name) === activeTabKey);

  React.useEffect(() => {
    const _activeLinkElement =
      tabLinksRef.current[tabs.findIndex((tab) => (tab.key || tab.props.name) === activeTabKey)];

    setActiveLinkElement(_activeLinkElement);
  }, [tabLinksRef.current, activeTabKey]);

  return (
    <Root>
      <TabLinks activeLeft={activeLinkElement?.offsetLeft} activeWidth={activeLinkElement?.offsetWidth}>
        {tabs.map((tab, index) => (
          <TabLink
            key={tab.key || tab.props.name}
            ref={(element) => {
              tabLinksRef.current[index] = element;
            }}
            onClick={() => setActiveTabKey(tab.key || tab.props.name)}
          >
            {tab.props.name}
          </TabLink>
        ))}
      </TabLinks>
      <div>{activeTab}</div>
    </Root>
  );
};
