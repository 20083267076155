import React from "react";
import classNames from "classnames";

import { Container } from "app-components/container";
import { Breakpoint } from "modules/DesignSystem/breakpoint";

import { SearchInput } from "./search-input";
import css from "./search-input.module.scss";

const threshold = 65; // height of input container

type Props = Omit<React.ComponentProps<typeof SearchInput>, "device"> & {
  onVisibilityChange: (isVisible: boolean) => void;
  isHidden: boolean;
};

export const SearchInputMobile = ({ onVisibilityChange, isHidden, ...props }: Props) => {
  const lastScroll = React.useRef(0);

  const handleScroll = () => {
    if (window.scrollY > lastScroll.current + threshold) {
      onVisibilityChange(true);
      lastScroll.current = window.scrollY;
    }

    if (window.scrollY > 0 && window.scrollY < lastScroll.current - threshold) {
      onVisibilityChange(false);
      lastScroll.current = window.scrollY;
    }
  };

  React.useEffect(() => {
    if (window.innerWidth < Breakpoint.lg) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container className={classNames(css.mobileContainer, { [css.hidden]: isHidden })}>
      <SearchInput {...props} device="mobile" />
    </Container>
  );
};
