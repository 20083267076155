import React, { useReducer, PropsWithChildren } from "react";

import * as homeScreen from "./homeScreen";
import { combineReducers } from "./utils";
import ApplicationStateContext from "./ApplicationStateContext";

const reducer = combineReducers({
  homeScreen: homeScreen.reducer,
});

const initialState = {
  homeScreen: homeScreen.initialState,
};

const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ApplicationStateContext.Provider value={{ state, dispatch }}>{children}</ApplicationStateContext.Provider>;
};

export default UserContextProvider;
