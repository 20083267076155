"use client";

import React, { createContext, useContext } from "react";
import { useRouter as usePageRouter } from "next/router";
import { useRouter as useAppRouter } from "next/navigation";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

const NextRouterContext = createContext<"app" | "page">(null);

export default function NextRouterProvider({ children, value }: { children: React.ReactNode; value: "app" | "page" }) {
  return <NextRouterContext.Provider value={value}>{children}</NextRouterContext.Provider>;
}

export const useRouter = () => {
  const context = useContext(NextRouterContext);

  if (!context) {
    throw new Error("useRouter must be used within a NextRouterProvider");
  }

  const router = context === "app" ? useAppRouter : usePageRouter;
  return { ...(router() as AppRouterInstance), context };
};
