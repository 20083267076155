import { getPathname, Pathname } from "../pathnames"; // Required relative path for next-sitemap.config.ts

export function buildLink(
  pathname: Pathname,
  locale: string,
  params?: Record<string, string | string[]>,
  fullUrl = false
) {
  const url = new URL(`${getPathname(pathname, locale).replace("[locale]", locale)}`, process.env.SITE_URL);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (url.pathname.includes(`[${key}]`)) {
        url.pathname = url.pathname.replace(`[${key}]`, String(value));
      } else if (Array.isArray(value)) {
        value.forEach((subValue) => {
          url.searchParams.append(key, subValue);
        });
      } else {
        url.searchParams.set(key, value);
      }
    });
  }

  if (!url.pathname.endsWith("/")) {
    url.pathname += "/";
  }

  if (fullUrl) {
    return url.href;
  }

  return url.pathname + url.search;
}
