"use client";

import React, { PureComponent } from "react";
import * as Sentry from "@sentry/nextjs";
import gtm from "react-gtm-module";
import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";
import api, { HalController } from "api-web-client";
import { useLocale, useTranslations } from "next-intl";

import { getDeviceID } from "modules/App/deviceId";
import { isServer } from "utils/runtime";
import { getLocationInfo } from "utils/getLocationInfo";
import { getUser, loginUser, logoutUser, registerUser, User } from "resources/AudiotekaApi";

import { useToaster } from "../Toaster";
import { userContext } from "./user.context";
import { userStore } from "./user.store";
import type { UserContext, UserErrors } from "./user.types";

type Props = {
  t: any;
  toaster: IToasterContext;
  locale: string;
  children: React.ReactNode;
};

interface State extends Omit<UserContext, "signIn" | "signOut" | "signUp" | "refreshUser"> {}

class UserProviderBase extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      hal: null,
      hasMarketingConsent: false,
      id: "",
      isAlphaTester: isServer ? false : localStorage.getItem("alphaTest") === "true",
      isClubMember: false,
      isLoading: true,
      isLoggedIn: false,
    };

    if (!isServer) {
      const alphaTest = new URL(window.location.href).searchParams.get("alphaTest");

      if (alphaTest) {
        localStorage.setItem("alphaTest", alphaTest);
      }
    }
  }

  public componentDidMount() {
    api.onMe = (userHal) => this.setUser(userHal ? (userHal.toObject() as User) : null);

    api.subscribe(({ commandName, response }) => {
      if (commandName === "RefreshTokenForWeb" && response.status === 422) {
        // Failed to refresh token - logout user.
        this.setUser(null);
      }
    });

    this.fetchUser();

    userStore.subscribe(async (state) => {
      if (state === "signedIn") {
        this.fetchUser();
      } else {
        this.setUser(null);
      }
    });
  }

  public fetchUser = async () => {
    try {
      this.setState({ isLoading: true });
      const user = await getUser();
      this.setUser(user);
    } catch {
      this.setUser(null);
    }
  };

  private setUser = (user: User) => {
    if (user) {
      gtm.dataLayer({
        dataLayer: {
          user: {
            audioteka_club_member: user.has_club,
            em: sha256(user.email).toString(),
            iuid: user.id,
            optin: user.newsletter,
            registered: new Date(user.created_at).getTime(),
            tracking_id: user.tracking_id,
            uid: sha1(user.id).toString(),
            web_version: 1,
          },
        },
      });
    }

    this.setState({
      email: user ? user.email : "",
      hal: user ? HalController.fromObject(user) : null, // TODO: remove after rewrite api requests (Sessions, Family-Sharing, Favourites, PlayerProvider)
      hasMarketingConsent: user ? user.marketing_consent : false,
      id: user ? user.id : "",
      isAlphaTester: localStorage.getItem("alphaTest") === "true",
      isClubMember: user ? user.has_club : false,
      isLoading: false,
      isLoggedIn: Boolean(user),
    });
  };

  public signIn: UserContext["signIn"] = async (email, password, recaptchaToken) => {
    const { locale, t, toaster } = this.props;

    try {
      const { catalogId } = getLocationInfo(locale);

      const { expires_at } = await loginUser({
        deviceId: getDeviceID(),
        email,
        password,
        catalogId,
        recaptchaToken,
      });

      userStore.setState("signedIn", new Date(expires_at));

      this.fetchUser();

      gtm.dataLayer({
        dataLayer: {
          event: "login",
          login_method: "standard",
        },
      });

      return true;
    } catch (error) {
      Sentry.captureException(error);

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_in.error_title"),
        message: t("auth.sign_in.error_message"),
        type: "error",
      });
    }

    return false;
  };

  public signOut: UserContext["signOut"] = async () => {
    try {
      const { locale } = this.props;
      const { catalogId } = getLocationInfo(locale);

      await logoutUser(catalogId);
    } finally {
      userStore.setState("signedOut");

      this.setUser(null);
    }
  };

  public signUp: UserContext["signUp"] = async (email, password, tos, marketing) => {
    this.setState({ isLoading: true }); // TODO move to auth form
    const errors: UserErrors = {};
    const { locale, t, toaster } = this.props;

    try {
      const { catalogId } = getLocationInfo(locale);

      await registerUser({
        email,
        password,
        termsOfService: tos,
        marketingConsent: marketing,
        catalogId,
      });

      gtm.dataLayer({
        dataLayer: {
          event: "sign_up_web",
        },
      });

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_up.success_title"),
        message: t("auth.sign_up.success_message"),
        type: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      if ("response" in error) {
        const response = await error.response.data;
        const errorList = response._embedded?.["app:error"] || [];

        errorList.forEach(({ property, code }) => {
          // eslint-disable-next-line default-case
          errors[property] = t(`auth.sign_up.error.${property}.${code.toLowerCase()}`);
        });
      }

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_up.error_title"),
        message: t("auth.sign_up.error_message"),
        type: "error",
      });

      this.setState({ isLoading: false });
    }

    return Object.keys(errors).length ? errors : null;
  };

  public render() {
    const { children } = this.props;

    return (
      <userContext.Provider
        value={{
          ...this.state,
          signIn: this.signIn,
          signOut: this.signOut,
          signUp: this.signUp,
          refreshUser: this.fetchUser,
        }}
      >
        {children}
      </userContext.Provider>
    );
  }
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations();
  const locale = useLocale();
  const toaster = useToaster();

  return (
    <UserProviderBase t={t} locale={locale} toaster={toaster}>
      {children}
    </UserProviderBase>
  );
};
