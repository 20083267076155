import { create } from "zustand";

interface State {
  signInRedirectParams: { [key: string]: string };
  setRedirectTo(redirectTo: string): void;
}

export const useSignInRedirectParams = create<State>((set) => ({
  signInRedirectParams: {},
  setRedirectTo(redirectTo: string) {
    if (redirectTo) {
      set({ signInRedirectParams: { redirectTo } });
    } else {
      set({ signInRedirectParams: {} });
    }
  },
}));
