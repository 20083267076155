import { v4 as uuidv4 } from "uuid";
import { isServer } from "utils/runtime";

const DEVICE_ID_KEY = "aud-device-id";

export function generateDeviceID(): string {
  const deviceID = uuidv4();

  localStorage.setItem(DEVICE_ID_KEY, deviceID);

  return deviceID;
}

export function getDeviceID(): string {
  if (isServer) {
    return "";
  }

  return localStorage.getItem(DEVICE_ID_KEY) || generateDeviceID();
}
