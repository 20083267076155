import { createContext } from "react";

import { cartConfig } from "./cart.config";
import { CartContext } from "./cart.types";

export const cartContext = createContext<CartContext>({
  items: [],
  currency: cartConfig.defaultCurrency,
  value: 0,
  isLoading: false,
  recentItem: null,
  addToCart: () => undefined,
  removeFromCart: () => undefined,
});

cartContext.displayName = "CartContext";
