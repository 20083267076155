import styled, { css } from "styled-components";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

export const Root = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
`;

export const TabLink = styled(ButtonPrimitive)`
  font-size: 14px;
  font-weight: 600;
`;

export const TabLinks = styled.div<{ activeWidth: number; activeLeft: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  //overflow: auto;
  padding-bottom: 4px;
  position: relative;
  width: fit-content;

  &::before {
    background: #380090 linear-gradient(109deg, #380089 0, #33adff 100px) no-repeat center right;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    transition: all 0.2s ease;
  }

  ${({ activeWidth, activeLeft }) => css`
    &::before {
      left: ${activeLeft}px;
      width: ${activeWidth}px;
    }
  `}
`;
