"use client";

import { createContext } from "react";

declare global {
  interface IToastAction {
    alt?: boolean;
    closeOnClick?: boolean;
    onClick?: () => void;
    text: string;
  }

  interface IToast {
    actions?: IToastAction[];
    actionsInline?: boolean;
    autoClose?: number;
    canClose?: boolean;
    close(): void;
    id?: string;
    message?: string;
    title: string;
    type?: ToastType;
  }

  interface IToasterContext {
    closeToast(id: string): void;
    showToast(toast: Omit<IToast, "close">): IToast;
  }
}

const ToasterContext = createContext<IToasterContext>({
  closeToast: () => undefined,
  showToast: () => undefined,
});

export default ToasterContext;
