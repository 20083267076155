import styled, { css } from "styled-components";

import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const SwiperBtn = styled(ButtonPrimitive)<{ direction: "previous" | "next" }>`
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 50%;
  display: flex;
  height: var(--swiper-btn-size);
  justify-content: center;
  position: absolute;
  top: calc(50% - calc(var(--swiper-btn-size) * 0.5));
  transform: scale(1);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
  width: var(--swiper-btn-size);
  z-index: 2;

  ${({ direction }) => css`
    ${direction === "next" ? "right" : "left"}: 0;
  `}

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const SwiperRoot = styled.div<{ arrowsBreakpoint?: keyof typeof MEDIA_QUERY }>`
  --swiper-btn-size: 48px;
  position: relative;

  ${({ arrowsBreakpoint = "xs" }) =>
    arrowsBreakpoint !== "xs"
      ? css`
          ${SwiperBtn} {
            display: none;
          }

          @media ${MEDIA_QUERY[arrowsBreakpoint]} {
            padding-inline: calc(var(--swiper-btn-size) * 0.5);

            ${SwiperBtn} {
              display: flex;
            }
          }
        `
      : ""}
`;

export const SwiperWrapper = styled.div<{ center?: boolean; gap?: number }>`
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  z-index: 1;

  ${({ center, gap = 16 }) => css`
    gap: ${gap}px;
    padding: 0 ${gap}px;
    width: ${center ? "min-content" : "100%"};
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex-shrink: 0;
    scroll-snap-align: center;
  }
`;
