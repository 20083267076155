import React, { PropsWithChildren } from "react";
import { useRouter } from "next/router";
import { NextIntlClientProvider } from "next-intl";
// eslint-disable-next-line import/no-unresolved
import { ReCaptchaProvider } from "next-recaptcha-v3";

import { ToasterContextProvider } from "modules/Toaster";
import ConfigProvider from "modules/Config/ConfigProvider";
import { TeaserTrackingProvider } from "modules/teaser-tracking";
import { CartProvider } from "modules/cart/provider";
import { FavouritesProvider } from "modules/Favourites/FavouritesProvider";
import { PlayerProvider } from "modules/player";
import { UserProvider } from "modules/user/user.provider";
import NextRouterProvider from "utils/next-router-provider";

import ApplicationStateProvider from "./store/ApplicationStateProvider";

export const AppProviders = ({ children, messages }: PropsWithChildren<{ messages: any }>) => {
  const {
    query: { locale },
  } = useRouter();

  return (
    <NextIntlClientProvider locale={locale as string} messages={messages} timeZone="Europe/Warsaw">
      <NextRouterProvider value="page">
        <ApplicationStateProvider>
          <ConfigProvider>
            <ToasterContextProvider>
              <ReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY} useEnterprise>
                <UserProvider>
                  <FavouritesProvider>
                    <PlayerProvider>
                      <CartProvider>
                        <TeaserTrackingProvider>{children}</TeaserTrackingProvider>
                      </CartProvider>
                    </PlayerProvider>
                  </FavouritesProvider>
                </UserProvider>
              </ReCaptchaProvider>
            </ToasterContextProvider>
          </ConfigProvider>
        </ApplicationStateProvider>
      </NextRouterProvider>
    </NextIntlClientProvider>
  );
};
