import styled, { css } from "styled-components";
import Button from "modules/DesignSystem/components/ButtonPrimitive";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const Close = styled(Button)`
  color: #979797;
  position: absolute;
  right: 16px;
  top: 16px;

  @media screen and ${MEDIA_QUERY.md} {
    right: 20px;
    top: 20px;
  }
`;

export const Container = styled.div<{ dark?: boolean; mobileFull?: boolean }>`
  background: ${({ dark }) => (dark ? "#160829" : "#fff")};
  color: ${({ dark }) => (dark ? "#fff" : "#000")};
  height: fit-content;
  max-width: 100%;
  padding: 32px 16px 24px;
  transition: transform 0.25s ease;

  @media screen and ${MEDIA_QUERY.md} {
    padding: 32px;
  }

  ${({ mobileFull }) =>
    mobileFull
      ? css`
          align-self: flex-end;
          border-radius: 16px 16px 0 0;
          max-height: 100%;
          overflow: auto;
          transform: translateY(100%);
          width: 100%;

          @media screen and ${MEDIA_QUERY.md} {
            align-self: flex-start;
            border-radius: 16px;
            height: auto;
            transform: translateY(-100px);
            width: auto;
          }
        `
      : css`
          border-radius: 16px;
          transform: translateY(-100px);
        `}
`;

export const Root = styled.div<{ center: boolean; mobileFull: boolean; open: boolean }>`
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  backdrop-filter: blur(2px);
  background: rgba(22, 8, 41, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity 0.25s ease, visibility 0.25s ease;
  width: 100%;
  z-index: 100;

  ${({ mobileFull }) =>
    mobileFull
      ? css`
          padding: 50px 0 0;

          @media screen and ${MEDIA_QUERY.md} {
            padding: 60px 16px;
          }
        `
      : css`
          padding: 60px 16px;
        `}

  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          visibility: visible;

          ${Container} {
            transform: translateY(0);
          }
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
