import gtm from "react-gtm-module";

import { playerStore } from "modules/player/player.store";
import { PlayerContext } from "modules/player/player.context";

const UP_TO_10_SECONDS = 10;
const UP_TO_1_MINUTE = 60;
const UP_TO_1_HOUR = 3600;

export function secondsToTimeString(seconds: number) {
  const time = Math.floor(seconds);
  const s = String(time % 60).padStart(2, "0");
  const m = String(Math.trunc((time / 60) % 60)).padStart(2, "0");
  const h = Math.trunc(time / 60 / 60);
  return `${h}:${m}:${s}`;
}

export async function smartResume(id: string, progress: number): Promise<number> {
  const storedProgress = await playerStore.getProgress(id);

  if (!storedProgress || storedProgress.progress !== progress) {
    // If not stored or storedProgress diffs from current one (could listen on other device) return current progress
    return Math.max(0, progress);
  }

  const timeDiff = Math.floor((Date.now() - storedProgress.createdAt) / 1000);

  if (timeDiff <= UP_TO_10_SECONDS) {
    return Math.max(0, progress - 2); // Rewind 2 seconds
  }
  if (timeDiff <= UP_TO_1_MINUTE) {
    return Math.max(0, progress - 5); // Rewind 5 seconds
  }
  if (timeDiff <= UP_TO_1_HOUR) {
    return Math.max(0, progress - 15); // Rewind 15 seconds
  }
  if (timeDiff > UP_TO_1_HOUR) {
    // More than 1 hour
    return Math.max(0, progress - 30); // Rewind 30 seconds
  }

  return Math.max(0, progress);
}

export function sendGTMPlayEvent(abook: PlayerContext["audiobook"]) {
  if (!abook) {
    return;
  }

  const history = JSON.parse(sessionStorage.getItem("player-history") || "[]");

  if (!history.includes(abook.id)) {
    gtm.dataLayer({
      dataLayer: {
        event: `play_${abook.isSample ? "sample" : "title"}`,
        [`${abook.isSample ? "sample" : "played_title"}_name`]: abook.title,
        [`${abook.isSample ? "sample" : "played_title"}_author`]: abook.authors.map(({ name }) => name).join(", "),
      },
    });

    history.push(abook.id);
    sessionStorage.setItem("player-history", JSON.stringify(history));
  }
}
