const activatorsSet = new Set<HTMLElement>();

let scrollDisabled = false;

export const disableScroll = (activator?: HTMLElement) => {
  if (activator) {
    activatorsSet.add(activator);
  }

  if (!scrollDisabled && document.body.scrollHeight > window.innerHeight) {
    scrollDisabled = true;

    document.body.dataset.scrollY = window.scrollY.toString();

    document.body.style.cssText = `
      overflow: hidden;
      margin-right: ${window.innerWidth - document.body.offsetWidth}px;
    `;
  }
};

export const enableScroll = (activator?: HTMLElement) => {
  if (activator) {
    activatorsSet.delete(activator);
  } else {
    activatorsSet.clear();
  }

  if (scrollDisabled && activatorsSet.size === 0) {
    scrollDisabled = false;

    const top = parseFloat(document.body.dataset.scrollY || "0");

    document.body.style.cssText = "";

    window.scrollTo({ behavior: "auto", top });
  }
};
