import React, { PropsWithChildren } from "react";

import { useUser } from "modules/user";

import { CartProvider as NewCartProvider } from "./cart.provider";
import { OldCartProvider } from "./old-cart.provider";

export function CartProvider({ children }: PropsWithChildren) {
  const user = useUser();

  if (user.isAlphaTester) {
    return <NewCartProvider>{children}</NewCartProvider>;
  }

  return <OldCartProvider>{children}</OldCartProvider>;
}
