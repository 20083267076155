import React, { useEffect, useState } from "react";
import api from "api-web-client";

const CONFIG_DEFAULT = {
  player_poll_interval_in_seconds: 60,
};

export const Config = React.createContext(CONFIG_DEFAULT);

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState(CONFIG_DEFAULT);

  useEffect(() => {
    api.follow("app:config").then(({ data: { player_poll_interval_in_seconds } }) => {
      setConfig({ player_poll_interval_in_seconds });
    });
  }, []);

  return <Config.Provider value={config}>{children}</Config.Provider>;
};

export default ConfigProvider;
