/* eslint-disable default-param-last */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

abstract class AxiosClient {
  protected axiosInstance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.axiosInstance = axios.create(config);
  }

  async get<Response>(config: Omit<AxiosRequestConfig, "method">) {
    return this._request<Response>({
      ...config,
      method: "GET",
    });
  }

  async post<Payload, Response>(config: Omit<AxiosRequestConfig, "method" | "data"> = {}, payload: Payload) {
    return this._request<Response>({
      ...config,
      method: "POST",
      data: payload,
    });
  }

  private async _request<Response>(config: AxiosRequestConfig) {
    const response = await this.axiosInstance.request<Response>({
      ...config,
      params: {
        ...config.params,
      },
    });

    return response.data;
  }
}

export default AxiosClient;
