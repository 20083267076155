import React from "react";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import { useUser } from "modules/user";

import { promoTypesConfig } from "./top-promo-bar.config";

const TopPromoBarComp = dynamic(() => import("./top-promo-bar"), {
  ssr: false,
});

const TopPromoBar = () => {
  const { isLoading: isLoadingUser, isClubMember } = useUser();

  if (isLoadingUser) {
    return null;
  }

  const isClubPromoAvailable = !isClubMember && cookie.get(promoTypesConfig.club.cookie.key) !== "closed";
  const availablePromo = isClubPromoAvailable ? "club" : null;

  if (!availablePromo || !(availablePromo in promoTypesConfig)) {
    return null;
  }

  const promo = promoTypesConfig[availablePromo];

  return <TopPromoBarComp promo={promo} />;
};

export default TopPromoBar;
