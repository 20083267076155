import React, { ReactNode } from "react";

import css from "./icon-with-badge.module.scss";

const IconWithBadge = ({ children, value }: { children: ReactNode; value: string | number }) => (
  <div className={css.iconWrapper}>
    <span className={css.badge}>{value}</span>
    {children}
  </div>
);

export default IconWithBadge;
