import styled, { css, keyframes } from "styled-components";

import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

import { toasterConfig } from "../../config";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const closeCounter = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

const getColorByType = (type: ToastType): string => {
  switch (type) {
    case "success":
      return "#22bb55";
    case "warning":
      return "#ffa42e";
    case "error":
      return "#f00";
    case "info":
      return "#5bbdff";
    default:
      return "#fff";
  }
};

export const ActionBtn = styled(ButtonPrimitive)<{ alt?: boolean }>`
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  padding: 4px 16px;

  ${({ alt }) => css`
    background-color: ${alt ? "#fff" : "transparent"};
    color: ${alt ? "#160829" : "#fff"};

    &:hover {
      background-color: ${alt ? "#fffd" : "#fff2"};
    }
  `}
`;

export const Actions = styled.div<{ inline?: boolean }>`
  display: flex;
  gap: 10px;
  justify-content: end;
  ${({ inline }) => css`
    grid-column: ${inline ? "2" : "1/3"};
    align-items: ${inline ? "center" : "stretch"};
  `}
`;

export const CloseBtn = styled(ButtonPrimitive)`
  align-self: start;
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
`;

export const Message = styled.p`
  color: #bbb7c5;
  font-size: 12px;
  grid-column: 1;
  line-height: 120%;
  margin: 0;
`;

export const Root = styled.div<{ autoClose?: number; closed?: boolean; height?: number; type: ToastType }>`
  animation: ${fadeIn} ${toasterConfig.revealingDuration}ms ease-in-out;
  background-color: #160829;
  border-radius: 8px;
  box-shadow: 0 4px 4px #0004;
  color: #fff;
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: 1fr auto;
  opacity: 1;
  overflow: hidden;
  padding: 12px;
  pointer-events: all;
  position: relative;
  transition: all ${toasterConfig.closingDuration}ms ease-out;
  width: min(100%, 300px);

  &::before {
    background-color: var(--toast-color);
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  ${({ type }) => css`
    --toast-color: ${getColorByType(type)};
  `}

  ${({ closed, height }) =>
    closed &&
    css`
      opacity: 0;
      margin-top: -${height + toasterConfig.gap}px;
    `}

  ${({ autoClose }) =>
    autoClose &&
    css`
      &::before {
        animation: ${closeCounter} ${autoClose}ms linear;
      }
    `}
`;

export const Title = styled.p`
  align-items: center;
  color: var(--toast-color);
  column-gap: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  grid-column: 1;
  margin: 0;
  line-height: 19px;

  > svg {
    align-self: start;
  }
`;
