export function stringifyPrice(price) {
  return price.toFixed(2).replace(".", ",");
}

export function parsePrice(price) {
  return parseFloat(price.replace(/^.*?(\d+,\d+).*?$/, "$1").replace(",", "."));
}

export function leadingZero(num, length) {
  const text = typeof num === "number" ? num.toString() : num;

  if (text.length < length) {
    return "0".repeat(length - text.length) + text;
  }

  return text;
}

export function normalizeDate(dateStr) {
  const date = new Date(dateStr);

  return `${leadingZero(date.getDate(), 2)}.${leadingZero(date.getMonth() + 1, 2)}.${date.getFullYear()}`;
}

export function isId(slugOrId) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(slugOrId);
}
