import React, { forwardRef, ButtonHTMLAttributes } from "react";
import styled from "styled-components";

const ButtonStyled = styled.button`
  color: inherit;
  display: inline-block;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
`;

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  type?: "button" | "submit" | "reset";
}
const ButtonPrimitive = forwardRef<HTMLButtonElement, Props>(({ children, ...props }, ref) => (
  <ButtonStyled type="button" ref={ref} {...props}>
    {children}
  </ButtonStyled>
));

export default ButtonPrimitive;
