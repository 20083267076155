/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useLocale } from "next-intl";
import Image from "next/image";
import classNames from "classnames";

import { Link } from "app-components/link";
import { Container } from "app-components/container";
import { ButtonPrimitive } from "app-components/button/button-primitive";

import { MenuMobileItem } from "../menu-mobile";
import { config } from "./config";
import { Catalog } from "./catalog";
import { Bottom } from "./bottom";
import css from "./footer.module.scss";

const Footer = ({ catalogItems }: { catalogItems: MenuMobileItem[] }) => {
  const [visibleColumns, setVisibleColumns] = useState([]);
  const locale = useLocale();

  const footerConfig = config[locale as string];

  const toggleColumnVisibility = (column: number) => {
    setVisibleColumns((columns) => {
      const index = columns.indexOf(column);

      if (index > -1) {
        columns.splice(index, 1);
      } else {
        columns.push(column);
      }

      return [...columns];
    });
  };

  return (
    <div className={css.footer}>
      <Catalog catalogItems={catalogItems} />
      <Container className={css.columns}>
        {footerConfig.columns.map((column, colIndex) => (
          <div className={css.column} key={column.title}>
            <h3
              className={classNames(css.title, {
                [css.expandable]: column.expandable,
                [css.expanded]: visibleColumns.includes(colIndex),
              })}
              onClick={column.expandable ? () => toggleColumnVisibility(colIndex) : undefined}
            >
              {column.title}
            </h3>
            {column.apps && (
              <div className={css.appLinkList}>
                <Link className={css.appLinkItem} href={column.apps.android}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    src="/gp-download.png"
                    alt="Get it on Google Play"
                    width="200"
                    height="60"
                  />
                </Link>
                <Link className={css.appLinkItem} href={column.apps.ios}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    src="/as-download.png"
                    alt="Download on the App Store"
                    width="200"
                    height="67"
                  />
                </Link>
              </div>
            )}
            {column.links && (
              <div
                className={classNames(css.linkList, {
                  [css.expandable]: column.expandable,
                  [css.expanded]: visibleColumns.includes(colIndex),
                })}
              >
                {column.links.map(({ text, className, ...linkProps }) =>
                  linkProps.route || linkProps.href ? (
                    <Link key={text} className={classNames(className, css.link)} {...linkProps}>
                      {text}
                    </Link>
                  ) : (
                    <ButtonPrimitive className={classNames(className, css.linkBtn)} key={text} {...linkProps}>
                      {text}
                    </ButtonPrimitive>
                  )
                )}
              </div>
            )}
          </div>
        ))}
      </Container>
      <Bottom socials={footerConfig.social} copy={footerConfig.copy} />
    </div>
  );
};

export default Footer;
