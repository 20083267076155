import { createContext } from "react";

import { TeaserTrackingContext } from "./teaser-tracking.types";

export const teaserTrackingContext = createContext<TeaserTrackingContext>({
  addList() {},
  addTeaser() {},
  removeList() {},
  removeTeaser() {},
  updateObserver() {},
});
