/* eslint-disable react/no-unknown-property */
import React, { ComponentProps } from "react";
import api from "api-web-client";
import App, { AppContext } from "next/app";
import { Nunito } from "next/font/google";
import cookie from "js-cookie";

import { AppContainer } from "modules/App";
import { getDeviceID } from "modules/App/deviceId";
import { AppProviders } from "modules/App/AppProviders";
import { getScreenCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";
import { isServer } from "utils/runtime";
import { getLocationInfo } from "utils/getLocationInfo";
import { localeToLang } from "utils/localeToLang";

import "../styles/normalize.scss";
import "../styles/global.scss";
import "../styles/scrollbar.scss";

const nunito = Nunito({
  subsets: ["latin-ext"],
  display: "swap",
  weight: "400",
});

const GlobalStyles = () => (
  <style jsx global>{`
    html {
      font-family: ${nunito.style.fontFamily};
      font-style: normal;
    }
  `}</style>
);

const apiUrl = new URL(`${isServer ? "/v2" : `/${cookie.get("store")}/v2`}`, process.env.API_URL);

api.initialize(apiUrl.toString(), {
  command: "RefreshTokenForWeb",
  payload: {
    device_id: getDeviceID(),
  },
});

function AudiotekaApp({ messages, ...props }: ComponentProps<typeof AppContainer> & { messages: any }) {
  return (
    <>
      <GlobalStyles />
      <AppProviders messages={messages}>
        <AppContainer {...props} />
      </AppProviders>
    </>
  );
}

AudiotekaApp.getInitialProps = async (appContext: AppContext) => {
  const {
    query: { locale },
  } = appContext.ctx;

  let catalogItems = [];
  let messages = null;
  let catalog = null;

  if (locale) {
    const { catalogId } = getLocationInfo(locale as string);
    catalog = await getScreenCatalog(catalogId);

    catalogItems = getCatalogItems(catalog);
    messages = (await import(`../../public/static/locales/${localeToLang(locale as string)}/common.json`)).default;
  }

  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, catalogItems, messages, catalog };
};

export default AudiotekaApp;
