import { isServer } from "utils/runtime";

export interface PlaybackProgress {
  progress: number;
  createdAt: number;
}

export interface PlayerSettings {
  muted: boolean;
  playbackRate: number;
  volume: number;
}

const DEFAULT_SETTINGS: PlayerSettings = {
  muted: false,
  playbackRate: 1,
  volume: 1,
};

class PlayerStore {
  private _db: Promise<IDBDatabase>;

  constructor() {
    if (isServer || !("indexedDB" in window)) {
      return;
    }

    const db = indexedDB.open("player", 3);

    db.addEventListener("upgradeneeded", () => {
      if (!db.result.objectStoreNames.contains("progress")) {
        db.result.createObjectStore("progress");
      }

      if (!db.result.objectStoreNames.contains("settings")) {
        db.result.createObjectStore("settings");
      }

      if (db.result.objectStoreNames.contains("sessions")) {
        db.result.deleteObjectStore("sessions");
      }
    });

    this._db = new Promise((resolve, reject) => {
      db.addEventListener("success", () => {
        resolve(db.result);
      });
      db.addEventListener("error", () => {
        reject(db.error);
      });
    });
  }

  public async getSettings(): Promise<PlayerSettings> {
    const tx = (await this._db).transaction("settings", "readonly");
    const store = tx.objectStore("settings");

    const request = store.openCursor();

    const result: PlayerSettings = { ...DEFAULT_SETTINGS };

    return new Promise((resolve) => {
      request.addEventListener("success", () => {
        const cursor = request.result;

        if (cursor) {
          result[cursor.primaryKey as any] = cursor.value;
          cursor.continue();
        } else {
          resolve(result);
        }
      });
      request.addEventListener("error", () => resolve(DEFAULT_SETTINGS));
    });
  }

  public async saveSettings(settings: Partial<PlayerSettings>): Promise<void> {
    const tx = (await this._db).transaction("settings", "readwrite");
    const store = tx.objectStore("settings");

    return new Promise((resolve, reject) => {
      tx.addEventListener("complete", () => resolve());
      tx.addEventListener("error", () => reject(tx.error));

      Object.keys(settings).forEach((key) => store.put(settings[key], key));
    });
  }

  public async saveProgress(id: string, progress: number): Promise<void> {
    const tx = (await this._db).transaction("progress", "readwrite");
    const store = tx.objectStore("progress");

    return new Promise((resolve, reject) => {
      tx.addEventListener("complete", () => resolve());
      tx.addEventListener("error", () => reject(new Error("Error while writing progress to store")));

      store.put({ progress, createdAt: Date.now() }, id);
    });
  }

  public async getProgress(id: string): Promise<PlaybackProgress | null> {
    const tx = (await this._db).transaction("progress", "readonly");
    const store = tx.objectStore("progress");

    const request = store.get(id);

    return new Promise((resolve, reject) => {
      request.addEventListener("success", () => resolve(request.result ?? null));
      request.addEventListener("error", () => reject(new Error("Error getting playbackProgress from store")));
    });
  }
}

export const playerStore = new PlayerStore();
