// eslint-disable-next-line no-shadow
export enum Breakpoint {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1524,
  fhd = 1920,
  wqhd = 2560,
}

export const MEDIA_QUERY = {
  xs: "",
  xsOnly: `(max-width: ${Breakpoint.sm - 1}px)`,
  sm: `(min-width: ${Breakpoint.sm}px)`,
  smOnly: `(min-width: ${Breakpoint.sm}px) and (max-width: ${Breakpoint.md - 1}px)`,
  smAndBelow: `(max-width: ${Breakpoint.md - 1}px)`,
  md: `(min-width: ${Breakpoint.md}px)`,
  mdOnly: `(min-width: ${Breakpoint.md}px) and (max-width: ${Breakpoint.lg - 1}px)`,
  mdAndBelow: `(max-width: ${Breakpoint.lg - 1}px)`,
  lg: `(min-width: ${Breakpoint.lg}px)`,
  lgOnly: `(min-width: ${Breakpoint.lg}px) and (max-width: ${Breakpoint.xl - 1}px)`,
  lgAndBelow: `(max-width: ${Breakpoint.xl - 1}px)`,
  xl: `(min-width: ${Breakpoint.xl}px)`,
  xlOnly: `(min-width: ${Breakpoint.xl}px) and (max-width: ${Breakpoint.xxl - 1}px)`,
  xlAndBelow: `(max-width: ${Breakpoint.xxl - 1}px)`,
  xxl: `(min-width: ${Breakpoint.xxl}px)`,
};

export const GUTTER = 30;

/**
 * breakpoints for new grid
 */
export const breakpoint = {
  tablet: "768px",
  laptop: "1024px",
  desktop: "1280px",
} as const;
