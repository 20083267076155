/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from "react";

import { Container } from "app-components/container";
import { disableScroll, enableScroll } from "utils/window";

import { RecommendationSection } from "./recommendation-section";
import { SearchResults } from "./search-results";
import { useRecommendations } from "./use-recommendations";
import css from "./search-and-recommendations.module.scss";

interface Props {
  searchResults: HALProduct[];
  searchTerm: string;
  visible: boolean;
  onClose: () => void;
}

const sectionIcons = ["popular", "recommended"] as const;

export function SearchAndRecommendations({ searchResults, searchTerm, visible, onClose }: Props) {
  const contentRef = useRef<HTMLDivElement>();

  const recommendations = useRecommendations();

  useEffect(() => {
    if (visible) {
      disableScroll();
    }

    return enableScroll;
  }, [visible]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [searchResults, recommendations]);

  if (!visible) return null;

  return (
    <div className={css.wrapper}>
      <div className={css.backdrop} onClick={onClose} />
      <div className={css.content} ref={contentRef}>
        <Container className={css.container}>
          {searchResults.length > 0 ? (
            <SearchResults results={searchResults} />
          ) : !searchTerm && recommendations ? (
            <>
              {recommendations._embedded["app:screen-section"].map((section, sectionIdx) => (
                <RecommendationSection key={section.id} icon={sectionIcons[sectionIdx]} section={section} />
              ))}
            </>
          ) : null}
        </Container>
      </div>
    </div>
  );
}
