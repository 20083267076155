import styled, { css } from "styled-components";

export const Grip = styled.div`
  background: #fff;
  border: solid 1px #eee;
  border-radius: 50%;
  box-shadow: 0 2px 4px #0004;
  height: 18px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.2s ease;
  width: 18px;
  z-index: 1;
`;

export const Input = styled.input`
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const Root = styled.div<{ checked?: boolean }>`
  border: solid 1px #eee;
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  position: relative;
  transition: background-color 0.2s ease;
  width: 44px;

  ${({ checked }) => css`
    background-color: ${checked ? "#3dffc5" : "#fff"};

    > ${Grip} {
      left: ${checked ? "calc(100% - 20px)" : "2px"};
    }
  `}
`;
