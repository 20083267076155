import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";
import { usePathname } from "next/navigation";

import { NewIcon } from "components/NewIcon";
import { Container } from "app-components/container";
import { Logo } from "app-components/logo";
import { Link } from "app-components/link";
import { ButtonPrimitive } from "app-components/button/button-primitive";
import { Breakpoint } from "modules/DesignSystem/breakpoint";
import { useUser } from "modules/user";
import { getUserCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";

import { MenuMobileItem } from "../menu-mobile";
import TopPromoBar from "./top-promo-bar";
import { CatalogDropdown } from "./catalog-dropdown/catalog-dropdown";
import { CatalogMenu } from "./catalog-menu";
import { SearchInput } from "./search-input/search-input";
import { SearchInputMobile } from "./search-input/search-input-mobile";
import { useSearchTerm } from "./search-input/useSearchTerm";
import { SearchAndRecommendations } from "./search-and-recommendations/search-and-recommendations";
import { IconButtons } from "./icon-buttons/icon-buttons";
import css from "./top-bar.module.scss";

interface Props {
  catalogItems: MenuMobileItem[];
}

const TopBar = ({ catalogItems }: Props) => {
  const catalogBtnRef = useRef<HTMLButtonElement>(null);
  const catalogContainerRef = useRef<HTMLDivElement>(null);
  const menuMobileRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  const { isLoggedIn, isLoading } = useUser();
  const t = useTranslations();

  const [isCatalogVisible, setCatalogVisible] = useState(false);
  const [isMobileInputHidden, setIsMobileInputHidden] = useState(false);
  const [catalog, setCatalog] = useState<MenuMobileItem[]>(catalogItems);

  const [searchVisible, setSearchVisible] = useState(false);
  const { searchTerm, onSearchTermChange, results, clearResults } = useSearchTerm();

  const closeResults = React.useCallback(() => {
    setSearchVisible(false);
    clearResults();
  }, []);

  useEffect(() => {
    closeResults();
  }, [pathname, closeResults]);

  const toggleCatalog = useCallback(() => {
    setCatalogVisible((visible) => !visible);
  }, []);

  useEffect(() => {
    async function loadUserCatalog() {
      if (!isLoading && isLoggedIn) {
        try {
          const userCatalog = await getUserCatalog();
          const userCatalogItems = getCatalogItems(userCatalog);
          setCatalog(userCatalogItems);
          return;
        } catch {
          setCatalog(catalogItems);
        }
      }
      setCatalog(catalogItems);
    }

    loadUserCatalog();
  }, [isLoading, isLoggedIn]);

  // Handle outside catalog click when visible
  useEffect(() => {
    if (isCatalogVisible) {
      const hOutsideClick = (event: PointerEvent) => {
        const path = event.composedPath();

        if (event.target instanceof HTMLElement) {
          if (
            path.includes(catalogContainerRef.current) ||
            path.includes(catalogBtnRef.current) ||
            path.includes(menuMobileRef.current)
          ) {
            if (event.target.tagName === "A") {
              setCatalogVisible(false);
            }
          } else {
            setCatalogVisible(false);
          }
        }
      };

      document.addEventListener("click", hOutsideClick);

      return () => {
        document.removeEventListener("click", hOutsideClick);
      };
    }

    return () => undefined;
  }, [isCatalogVisible]);

  // Recalculate offset left on catalog open && window resize
  useEffect(() => {
    if (isCatalogVisible) {
      const checkOffsetLeft = () => {
        const catalogOffsetLeft = parseFloat(catalogContainerRef.current.style.left || "0");

        if (window.innerWidth < Breakpoint.md) {
          if (catalogOffsetLeft !== 0) {
            catalogContainerRef.current.style.left = "0";
          }
        } else if (catalogOffsetLeft !== catalogBtnRef.current.offsetLeft) {
          catalogContainerRef.current.style.left = `${catalogBtnRef.current.offsetLeft}px`;
        }
      };

      checkOffsetLeft();

      window.addEventListener("resize", checkOffsetLeft);

      return () => {
        window.removeEventListener("resize", checkOffsetLeft);
      };
    }

    return () => undefined;
  }, [isCatalogVisible]);

  return (
    <div
      id="layout-top-bar"
      className={classNames(
        css.topBar,
        { [css.searchVisible]: searchVisible },
        { [css.mobileInputHidden]: isMobileInputHidden }
      )}
    >
      <TopPromoBar />
      <div className={css.topBarBackground}>
        <Container className={css.container}>
          <div className={css.wrapper}>
            <div className={css.column}>
              <button type="button" className={css.catalogMobileButton} onClick={toggleCatalog}>
                <NewIcon icon="hamburger" width="24" height="24" />
              </button>
              <Link className={css.logoLink} route="home" aria-label="Audioteka">
                <Logo className={css.logo} variant="audioteka" />
              </Link>
              <button
                type="button"
                className={classNames(css.catalogButton, { [css.open]: isCatalogVisible })}
                onClick={toggleCatalog}
                ref={catalogBtnRef}
              >
                {t("navigation.catalog")}
                <NewIcon icon="chevron-down" width="16" height="16" />
              </button>
            </div>
            <SearchInput
              value={searchTerm}
              device="desktop"
              onChange={onSearchTermChange}
              clearResults={closeResults}
              onFocus={() => setSearchVisible(true)}
            />
            <div className={classNames(css.column, css.columnWithIcons)}>
              <IconButtons />
              <ButtonPrimitive
                className={classNames(css.closeSearchButton, { [css.visible]: searchVisible })}
                onClick={closeResults}
              >
                {t("navigation.close_search")}
              </ButtonPrimitive>
            </div>
          </div>
          <CatalogDropdown
            items={catalog}
            isVisible={isCatalogVisible}
            containerRef={catalogContainerRef}
            onToggle={toggleCatalog}
          />
        </Container>
        <SearchInputMobile
          value={searchTerm}
          isHidden={isMobileInputHidden}
          onChange={onSearchTermChange}
          clearResults={closeResults}
          onFocus={() => setSearchVisible(true)}
          onVisibilityChange={setIsMobileInputHidden}
        />
        <CatalogMenu open={isCatalogVisible} items={catalog} onClose={toggleCatalog} />
        <SearchAndRecommendations
          searchResults={results}
          searchTerm={searchTerm}
          visible={searchVisible}
          onClose={closeResults}
        />
      </div>
    </div>
  );
};

export default TopBar;
