import styled, { css } from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";

const Container = styled.div`
  width: 100%;
  max-width: 324px;
  margin: 0 auto;

  @media (min-width: ${breakpoint.tablet}) {
    max-width: 45.75rem;
  }

  @media (min-width: ${breakpoint.laptop}) {
    max-width: 60rem;
  }

  @media (min-width: ${breakpoint.desktop}) {
    max-width: 74rem;
  }
`;

const Row = styled.div`
  display: grid;
  column-gap: 0.75rem;
  grid-template-columns: repeat(12, 1fr);

  @media (min-width: ${breakpoint.tablet}) {
    column-gap: 1rem;
  }
`;

type ColumnSpan = {
  [key in keyof typeof breakpoint | "_"]?: number;
};

type ColumnOffset = {
  [key in keyof typeof breakpoint | "_"]?: number | "auto";
};

const generateSpanCss = (span?: ColumnSpan) => {
  if (!span) return "grid-column: auto /span 12";

  // 12 column span is default
  const newSpan = { _: 12, ...span };

  return Object.keys(newSpan)
    .map((key) => {
      if (key === "_") {
        return `grid-column: auto /span ${newSpan[key]}`;
      }

      if (key === "tablet" || key === "laptop" || key === "desktop") {
        return `@media (min-width: ${breakpoint[key]}) { 
          grid-column: auto / span ${newSpan[key]}; 
        }`;
      }

      return "";
    })
    .join(";");
};

const generateOffsetCss = (offset?: ColumnOffset) => {
  if (!offset) return "";

  return Object.keys(offset)
    .map((key) => {
      if (key === "_") {
        return `grid-column-start: ${offset[key]}`;
      }

      if (key === "tablet" || key === "laptop" || key === "desktop") {
        return `@media (min-width: ${breakpoint[key]}) { 
          grid-column-start: ${offset[key]}; 
        }`;
      }

      return "";
    })
    .join(";");
};

const Column = styled.div<{ span?: ColumnSpan; $offset?: ColumnOffset }>`
  ${({ span, $offset }) => css`
    ${generateSpanCss(span)};
    ${generateOffsetCss($offset)};
  `}
`;

export default { Container, Row, Column };
